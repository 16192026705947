import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { auth, db } from '../Universal/firebase';
import { doc, getDoc, collection, query, where, getDocs, updateDoc } from 'firebase/firestore';
import Navbar from '../Universal/Navbar';
import { CheckSquare, Square, Star, ChevronDown, ChevronUp } from 'lucide-react';

const TrainingDataEntry = ({ entry, index, onSave, onCancel, editingIndex, setEditingIndex }) => {
  const [editableData, setEditableData] = useState(entry);

  useEffect(() => {
    if (editingIndex !== index) {
      setEditableData(entry);
    }
  }, [editingIndex, index, entry]);

  const handleChange = (field, value) => {
    setEditableData(prev => ({ ...prev, [field]: value }));
  };

  const isEditing = editingIndex === index;
  const dateStr = entry.timestamp ? new Date(entry.timestamp).toLocaleString() : 'N/A';

  if (isEditing) {
    return (
      <div style={{ border: '1px solid #ededed', borderRadius: '8px', padding: '10px', marginBottom: '10px' }}>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
          <label style={{ fontWeight: '600', fontSize: '14px' }}>Question:</label>
          <input
            type="text"
            value={editableData.question || ''}
            onChange={(e) => handleChange('question', e.target.value)}
            style={{ padding: '5px', border: '1px solid #ccc', borderRadius: '4px' }}
          />

          <label style={{ fontWeight: '600', fontSize: '14px' }}>Rubric:</label>
          <textarea
            value={editableData.rubric || ''}
            onChange={(e) => handleChange('rubric', e.target.value)}
            style={{ padding: '5px', border: '1px solid #ccc', borderRadius: '4px', height: '80px' }}
          />

          <label style={{ fontWeight: '600', fontSize: '14px' }}>Score:</label>
          <input
            type="number"
            value={editableData.score || 0}
            onChange={(e) => handleChange('score', parseInt(e.target.value) || 0)}
            style={{ padding: '5px', border: '1px solid #ccc', borderRadius: '4px', width: '60px' }}
          />

          <label style={{ fontWeight: '600', fontSize: '14px' }}>Student Response:</label>
          <textarea
            value={editableData.studentResponse || ''}
            onChange={(e) => handleChange('studentResponse', e.target.value)}
            style={{ padding: '5px', border: '1px solid #ccc', borderRadius: '4px', height: '80px' }}
          />
        </div>

        <div style={{ display: 'flex', gap: '10px', marginTop: '10px' }}>
          <button
            onClick={() => {
              const updatedEntry = { ...editableData, timestamp: Date.now() };
              onSave(index, updatedEntry);
              setEditingIndex(null);
            }}
            style={{
              background: '#2BB514',
              color: 'white',
              border: 'none',
              borderRadius: '5px',
              padding: '5px 10px',
              cursor: 'pointer',
              fontFamily: "'Montserrat', sans-serif",
              fontWeight: '500'
            }}
          >
            Save
          </button>
          <button
            onClick={() => {
              onCancel();
              setEditingIndex(null);
            }}
            style={{
              background: 'white',
              color: 'grey',
              border: '1px solid lightgrey',
              borderRadius: '5px',
              padding: '5px 10px',
              cursor: 'pointer',
              fontFamily: "'Montserrat', sans-serif",
              fontWeight: '500'
            }}
          >
            Cancel
          </button>
        </div>
      </div>
    );
  }

  // View mode
  return (
    <div
      key={index}
      style={{
        border: '1px solid #ededed',
        borderRadius: '8px',
        padding: '10px',
        marginBottom: '10px',
        background: '#fafafa'
      }}
    >
      <p><strong>Question:</strong> {entry.question || 'N/A'}</p>
      <p><strong>Rubric:</strong> {entry.rubric || 'N/A'}</p>
      <p><strong>Score:</strong> {entry.score !== undefined ? entry.score : 'N/A'}</p>
      <p><strong>Student Response:</strong> {entry.studentResponse || 'N/A'}</p>
      <p><strong>Timestamp:</strong> {dateStr}</p>
      <button
        onClick={() => setEditingIndex(index)}
        style={{
          marginTop: '10px',
          background: 'white',
          color: 'grey',
          border: '1px solid lightgrey',
          borderRadius: '5px',
          padding: '5px 10px',
          cursor: 'pointer',
          fontFamily: "'Montserrat', sans-serif",
          fontWeight: '500'
        }}
      >
        Edit
      </button>
    </div>
  );
};

const TeacherClassSettings = () => {
  const { classId } = useParams();
  const navigate = useNavigate();
  const [allTeacherClasses, setAllTeacherClasses] = useState([]);
  const [currentClassData, setCurrentClassData] = useState(null);
  const [sourceClassId, setSourceClassId] = useState(null); // Single source
  const [targetClassIds, setTargetClassIds] = useState([]); // Multiple targets
  const [loading, setLoading] = useState(false);
  const [editingIndex, setEditingIndex] = useState(null);
  const [showTrainingData, setShowTrainingData] = useState(false); // Collapsed by default

  const currentUser = auth.currentUser;

  useEffect(() => {
    if (!currentUser) return;

    const fetchClasses = async () => {
      setLoading(true);
      try {
        const classesRef = collection(db, 'classes');
        const q = query(classesRef, where('teacherUID', '==', currentUser.uid));
        const snapshot = await getDocs(q);

        let fetchedClasses = [];
        snapshot.forEach(docSnap => {
          fetchedClasses.push({ id: docSnap.id, ...docSnap.data() });
        });

        setAllTeacherClasses(fetchedClasses);

        // Fetch current class data
        const currentClassRef = doc(db, 'classes', classId);
        const currentClassSnap = await getDoc(currentClassRef);
        if (currentClassSnap.exists()) {
          setCurrentClassData({ id: classId, ...currentClassSnap.data() });
        }
      } catch (error) {
        console.error("Error fetching classes:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchClasses();
  }, [classId, currentUser]);

  const handleToggleTargetClass = (id) => {
    if (targetClassIds.includes(id)) {
      setTargetClassIds(targetClassIds.filter(cid => cid !== id));
    } else {
      setTargetClassIds([...targetClassIds, id]);
    }
  };

  const handleLinkData = async () => {
    if (!currentClassData || !sourceClassId) {
      alert('Please select a source class with training data first.');
      return;
    }

    // Get the source class data
    const sourceClass = allTeacherClasses.find(c => c.id === sourceClassId);
    if (!sourceClass || !sourceClass.aiTrainingData || sourceClass.aiTrainingData.length === 0) {
      alert('The selected source class has no training data.');
      return;
    }

    // Merge source class' aiTrainingData into each selected target class
    if (targetClassIds.length === 0) {
      alert('Please select at least one target class.');
      return;
    }

    for (const tId of targetClassIds) {
      const targetClassRef = doc(db, 'classes', tId);
      const targetSnap = await getDoc(targetClassRef);
      if (targetSnap.exists()) {
        const targetData = targetSnap.data();
        const newData = (targetData.aiTrainingData || []).concat(sourceClass.aiTrainingData);
        await updateDoc(targetClassRef, {
          aiTrainingData: newData
        });
      }
    }

    alert('Training data linked successfully to selected classes!');
    setTargetClassIds([]);
  };

  const handleSaveEntry = async (index, updatedEntry) => {
    if (!currentClassData) return;

    const updatedData = [...(currentClassData.aiTrainingData || [])];
    updatedData[index] = updatedEntry;

    try {
      const currentClassRef = doc(db, 'classes', classId);
      await updateDoc(currentClassRef, {
        aiTrainingData: updatedData
      });
      setCurrentClassData(prev => ({ ...prev, aiTrainingData: updatedData }));
      alert("Entry updated!");
    } catch (error) {
      console.error("Error saving entry:", error);
      alert("Failed to save entry.");
    }
  };

  const handleCancelEdit = () => {
    setEditingIndex(null);
  };

  const currentClassName = currentClassData?.className || 'Current Class';
  const otherClasses = allTeacherClasses.filter(c => c.id !== classId);

  const classesWithData = allTeacherClasses.filter(c => c.aiTrainingData && c.aiTrainingData.length > 0);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: 'white', position: 'absolute', left: 0, right: 0, bottom: 0, top: 0 }}>
      <Navbar userType="teacher" />
      <div style={{ padding: '20px', marginLeft: '200px', marginTop: '120px' }}>
        <h1 style={{ fontFamily: "'Montserrat', sans-serif", fontSize: '24px', fontWeight: '600' }}>Class Settings</h1>
        
        <button
          style={{
            background: 'white',
            color: 'grey',
            border: '1px solid lightgrey',
            borderRadius: '5px',
            padding: '10px 20px',
            cursor: 'pointer',
            marginTop: '20px',
            fontFamily: "'Montserrat', sans-serif",
            fontWeight: '500'
          }}
          onClick={() => navigate(-1)}
        >
          Go Back
        </button>

        {/* Linking Section */}
        <div style={{ marginTop: '40px', borderTop: '1px solid #ededed', paddingTop: '20px' }}>
          <h2 style={{ fontFamily: "'Montserrat', sans-serif", fontSize: '20px', fontWeight: '500' }}>
            Use Training Data
          </h2>

          {loading ? (
            <p>Loading classes...</p>
          ) : (
            <>
            {/* Line 1: Source Class Selection */}
            <div style={{ marginBottom: '20px' }}>
              <p style={{ color: 'grey', fontSize: '14px' }}>
                Which class do you want to use the training data of?
              </p>
              {classesWithData.length > 0 ? (
                classesWithData.map(c => (
                  <label key={c.id} style={{ display: 'block', marginBottom: '8px', cursor: 'pointer' }}>
                    <input
                      type="radio"
                      name="sourceClass"
                      value={c.id}
                      onChange={() => {
                        setSourceClassId(c.id);
                        // Clear target selections when source changes
                        setTargetClassIds([]);
                      }}
                      checked={sourceClassId === c.id}
                      style={{ marginRight: '10px' }}
                    />
                    {c.className} <Star size={15} color="#F6C026" style={{ marginLeft: '5px' }} />
                    <span style={{ marginLeft: '10px', fontSize: '12px', color: 'grey' }}>
                      {c.aiTrainingData.length} items
                    </span>
                  </label>
                ))
              ) : (
                <p style={{ fontSize: '14px', color: 'grey' }}>No classes with training data found.</p>
              )}
            </div>

            {/* Line 2: Target Classes Selection */}
            <div style={{ marginBottom: '20px' }}>
              <p style={{ color: 'grey', fontSize: '14px' }}>
                Which classes do you want to use it too?
              </p>
              {allTeacherClasses.length > 0 ? (
                allTeacherClasses
                  .filter(c => c.id !== classId && c.id !== sourceClassId) // Exclude both current class and source class
                  .map(c => {
                    const isSelected = targetClassIds.includes(c.id);
                    const hasTrainingData = c.aiTrainingData && c.aiTrainingData.length > 0;
                    return (
                      <div
                        key={c.id}
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          marginBottom: '10px',
                          cursor: 'pointer'
                        }}
                        onClick={() => handleToggleTargetClass(c.id)}
                      >
                        {isSelected ? <CheckSquare color="#2BB514" /> : <Square color="grey" />}
                        <span style={{ marginLeft: '10px', fontFamily: "'Montserrat', sans-serif", fontWeight: '500' }}>
                          {c.className || c.id}
                        </span>
                        {hasTrainingData && <Star size={15} color="#F6C026" style={{ marginLeft: '5px' }} />}
                      </div>
                    );
                  })
              ) : (
                <p style={{ fontSize: '14px', color: 'grey' }}>No classes found.</p>
              )}
            </div>

            <button
              onClick={handleLinkData}
              style={{
                marginTop: '20px',
                padding: '10px 20px',
                border: '1px solid #ededed',
                borderRadius: '5px',
                background: '#f0f0f0',
                cursor: 'pointer',
                fontFamily: "'Montserrat', sans-serif",
                fontWeight: '500'
              }}
            >
              Link Selected Data
            </button>
          </>
        )}
      </div>

        {/* Current Class Training Data */}
        <div style={{ marginTop: '40px', borderTop: '1px solid #ededed', paddingTop: '20px' }}>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <h2 style={{ fontFamily: "'Montserrat', sans-serif", fontSize: '20px', fontWeight: '500' }}>
              {currentClassName} Training Data
            </h2>
            <button
              onClick={() => setShowTrainingData(!showTrainingData)}
              style={{
                background: 'white',
                border: '1px solid lightgrey',
                borderRadius: '5px',
                padding: '5px 10px',
                cursor: 'pointer',
                fontFamily: "'Montserrat', sans-serif",
                fontWeight: '500',
                color: 'grey',
                display: 'flex',
                alignItems: 'center',
                gap: '5px'
              }}
            >
              {showTrainingData ? 'Hide' : 'Show'}
              {showTrainingData ? <ChevronUp size={16} color="grey" /> : <ChevronDown size={16} color="grey" />}
            </button>
          </div>

          {currentClassData?.aiTrainingData && currentClassData.aiTrainingData.length > 0 ? (
            showTrainingData ? (
              <div style={{ marginTop: '20px' }}>
                {currentClassData.aiTrainingData.map((entry, index) => (
                  <TrainingDataEntry
                    key={index}
                    entry={entry}
                    index={index}
                    onSave={handleSaveEntry}
                    onCancel={handleCancelEdit}
                    editingIndex={editingIndex}
                    setEditingIndex={setEditingIndex}
                  />
                ))}
              </div>
            ) : (
              <p style={{ fontSize: '14px', color: 'grey', marginTop: '10px' }}>Training data is hidden.</p>
            )
          ) : (
            <p style={{ fontSize: '14px', color: 'grey', marginTop: '20px' }}>No training data available for this class.</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default TeacherClassSettings;
