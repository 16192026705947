import React, { useState, useEffect } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../Universal/firebase';
import { useNavigate, useParams } from 'react-router-dom';
import Navbar from '../Universal/Navbar';
import {
  Search,
  SquareCheck,
  Pause,
  SquareMinus,
  SquareX,
} from 'lucide-react';
import GradeProgressionChart2 from './Results/TeacherStudentView/GradeChart2';
import 'react-datepicker/dist/react-datepicker.css';
import styled from 'styled-components';
import StyledDatePickerComponent from './StyledDatePicker'; // Updated import

function TeacherStudentGrades() {
  const [assignments, setAssignments] = useState([]);
  const [studentName, setStudentName] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [hoveredAssignmentId, setHoveredAssignmentId] = useState(null);
  const [startDate, setStartDate] = useState(null); // For date range filtering
  const [endDate, setEndDate] = useState(null);
  const [originalAssignments, setOriginalAssignments] = useState([]);

  const { classId, studentUid } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    const fetchAllAssignments = async () => {
      try {
        // Fetch student data and name
        const studentDoc = await getDoc(doc(db, 'students', studentUid));
        if (studentDoc.exists()) {
          const studentData = studentDoc.data();
          setStudentName(`${studentData.firstName} ${studentData.lastName}`);
  
          // Get graded assignments
          const classGrades = studentData[`class_${classId}`]?.grades || {};
          const gradedAssignments = Object.entries(classGrades).map(
            ([assignmentId, gradeData]) => ({
              id: assignmentId,
              status: 'completed',
              ...gradeData,
              type: assignmentId.split('+').pop(),
              submittedAt: gradeData.submittedAt,
              date: gradeData.submittedAt, // Original submission date
            })
          );
  
          // Get assignments in different states
          const assignmentsToTake = studentData.assignmentsToTake || [];
          const assignmentsInProgress = studentData.assignmentsInProgress || [];
          const assignmentsPaused = studentData.assignmentsPaused || [];
  
          // Filter assignments for this class
          const relevantAssignmentIds = [
            ...new Set([
              ...assignmentsToTake,
              ...assignmentsInProgress,
              ...assignmentsPaused,
            ]),
          ].filter((id) => id.startsWith(classId));
  
          // Fetch details for non-graded assignments
          const assignmentPromises = relevantAssignmentIds.map(async (id) => {
            const assignmentDoc = await getDoc(doc(db, 'assignments', id));
            if (assignmentDoc.exists()) {
              const data = assignmentDoc.data();
              const dueDate = data.dueDate ? new Date(data.dueDate) : new Date();
              return {
                id,
                assignmentName: data.assignmentName,
                type: id.split('+').pop(),
                dueDate: data.dueDate,
                date: {
                  toDate: () => dueDate,
                },
                status: assignmentsPaused.includes(id)
                  ? 'paused'
                  : assignmentsInProgress.includes(id)
                  ? 'in_progress'
                  : 'not_started',
                score: null, // Use null for incomplete assignments
              };
            }
            return null;
          });
  
          let nonGradedAssignments = (await Promise.all(assignmentPromises)).filter(
            (assignment) => assignment !== null
          );
  
          // **Deduplication Step: Remove Non-Graded Assignments that are Already Graded**
          const gradedAssignmentIds = new Set(gradedAssignments.map(a => a.id));
          nonGradedAssignments = nonGradedAssignments.filter(
            (assignment) => !gradedAssignmentIds.has(assignment.id)
          );
  
          // **Final Deduplication Using a Map (Ensures Uniqueness)**
          const uniqueAssignmentsMap = new Map();
  
          // Add graded assignments first
          gradedAssignments.forEach(assignment => {
            uniqueAssignmentsMap.set(assignment.id, assignment);
          });
  
          // Add non-graded assignments, ensuring no duplicates
          nonGradedAssignments.forEach(assignment => {
            if (!uniqueAssignmentsMap.has(assignment.id)) {
              uniqueAssignmentsMap.set(assignment.id, assignment);
            }
          });
  
          // Convert Map back to array
          const allAssignments = Array.from(uniqueAssignmentsMap.values()).sort(
            (a, b) => {
              const dateA =
                a.status === 'completed'
                  ? a.submittedAt.toDate()
                  : new Date(a.dueDate || 0);
  
              const dateB =
                b.status === 'completed'
                  ? b.submittedAt.toDate()
                  : new Date(b.dueDate || 0);
  
              return dateA - dateB; // Adjust sort order as needed
            }
          );
  
          setOriginalAssignments(allAssignments); // Store original data
          setAssignments(allAssignments);
        }
      } catch (error) {
        console.error('Error fetching assignments:', error);
      }
    };
  
    fetchAllAssignments();
  }, [classId, studentUid]);
  

  // Function to calculate overall average including incomplete assignments
  const calculateAverageScore = () => {
    if (assignments.length === 0) return 0;

    const totalScore = assignments.reduce((acc, assignment) => {
      if (
        assignment.status === 'completed' &&
        typeof assignment.score === 'number'
      ) {
        return acc + assignment.score;
      } else {
        // Assign zero for incomplete assignments
        return acc + 0;
      }
    }, 0);

    return Math.round(totalScore / assignments.length);
  };

  const averageScore = calculateAverageScore();

  // Function to filter assignments based on search term and date range
  const getFilteredAssignments = () => {
    if (!originalAssignments) return [];
    
    return originalAssignments.filter((assignment) => {
      // Search term filter
      const matchesSearch = !searchTerm || 
        (assignment.assignmentName || '')
          .toLowerCase()
          .includes(searchTerm.toLowerCase().trim());

      // Date filter
      const assignmentDate =
        assignment.status === 'completed'
          ? assignment.submittedAt.toDate()
          : new Date(assignment.dueDate || 0);

      const withinStartDate = !startDate || assignmentDate >= startDate;
      const withinEndDate = !endDate || assignmentDate <= endDate;

      return matchesSearch && withinStartDate && withinEndDate;
    });
  };

  useEffect(() => {
    setAssignments(getFilteredAssignments());
  }, [searchTerm, startDate, endDate, originalAssignments]);



  const getLetterGrade = (percentage) => {
    if (percentage >= 90) return 'A';
    if (percentage >= 80) return 'B';
    if (percentage >= 70) return 'C';
    if (percentage >= 60) return 'D';
    return 'F';
  };

  const getGradeColors = (grade) => {
    if (grade === undefined || grade === null)
      return { color: 'grey', background: '#f4f4f4' };
    if (grade < 50) return { color: '#FF0000', background: '#FFCBCB' };
    if (grade < 70) return { color: '#FF4400', background: '#FFC6A8' };
    if (grade < 80) return { color: '#EFAA14', background: '#FFF4DC' };
    if (grade < 90) return { color: '#9ED604', background: '#EDFFC1' };
    if (grade > 99) return { color: '#E01FFF', background: '#F7C7FF' };
    return { color: '#2BB514', background: '#D3FFCC' };
  };

  const getStatusIcon = (status) => {
    switch (status) {
      case 'completed':
        return (
          <SquareCheck
            size={20}
            color="#00DE09"
            style={{ marginRight: '20px' }}
          />
        );
      case 'paused':
        return <Pause size={20} color="#FF4d00" style={{ marginRight: '20px' }} />;
      case 'in_progress':
        return (
          <SquareMinus size={20} color="#FFA500" style={{ marginRight: '20px' }} />
        );
      case 'not_started':
        return <SquareX size={20} color="grey" style={{ marginRight: '20px' }} />;
      default:
        return null;
    }
  };

  const navigateToResults = (assignment) => {
    if (assignment.status !== 'completed') return;

    const { type, id } = assignment;
    if (type === 'OE') {
      navigate(`/teacherStudentResults/${id}/${studentUid}/${classId}`);
    } else if (type === 'AMCQ') {
      navigate(`/teacherStudentResultsAMCQ/${id}/${studentUid}/${classId}`);
    } else if (type === 'MCQ') {
      navigate(`/teacherStudentResultsMCQ/${id}/${studentUid}/${classId}`);
    }
  };

  return (
    <div
      style={{
        minHeight: '100vh',
        width: '100%',
        backgroundColor: '#FFFFFF',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        fontFamily: "'Montserrat', sans-serif",
      }}
    >
      <Navbar userType="teacher" />

      <div
        style={{
          width: 'calc(100% - 200px)',
          marginLeft: '200px',
        }}
      >
        {/* Fixed Header */}
        <div
          style={{
            padding: '20px 4%',
            height: '120px',
            position: 'fixed',
            width: '92%',
            top: '0px',
            zIndex: '30',
            background: 'rgb(255,255,255,.95)',
            backdropFilter: 'blur(5px)',
            borderBottom: '1px solid lightgrey',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            {/* Student Name */}
            <h1 style={{ fontSize: '30px', fontWeight: '500' }}>
              {studentName}
            </h1>
            <div
              style={{
                position: 'relative',
                marginLeft: 'auto',
                marginRight: 'calc(4% + 130px)',
              }}
            >
              <input
                type="text"
                placeholder="Search by Assignment Name..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                style={{
                  width: '200px',
                  height: '35px',
                  fontSize: '14px',
                  border: '1px solid lightgrey',
                  padding: '0px 35px 0px 10px',
                  backgroundColor: 'white',
                  color: 'black',
                  borderRadius: '5px',
                  outline: 'none',
                }}
              />
              <Search
                size={20}
                strokeWidth={2.5}
                color="grey"
                style={{
                  position: 'absolute',
                  right: '10px',
                  top: '50%',
                  transform: 'translateY(-50%)',
                  cursor: 'pointer',
                }}
              />
            </div>
            {/* Overall Grade */}
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div
              style={{
                fontSize: '18px',
                padding: '5px 10px',
                borderRadius: '5px',
                color: getGradeColors(averageScore).color,
                backgroundColor: getGradeColors(averageScore).background,
                display: 'flex',
                textAlign: 'center',
                gap: '10px',
                marginRight: '20px',
              }}
            >
              {averageScore}%
            </div>

            <div
              className="date-picker-container"
              style={{ display: 'flex', alignItems: 'center', gap: '15px', marginLeft: 'auto', marginRight: 'calc(4% + 130px)' }}
            >
              <label htmlFor="start-date">From:</label>
              <StyledDatePickerComponent
                id="start-date"
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                selectsStart
                startDate={startDate}
                endDate={endDate}
                isClearable
                placeholderText="Start Date"
              />
              <label htmlFor="end-date">To:</label>
              <StyledDatePickerComponent
                id="end-date"
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                minDate={startDate}
                isClearable
                placeholderText="End Date"
              />
            </div>
          </div>
        </div>

        {/* Spacer to avoid content being hidden behind fixed header */}
        <div style={{ height: '160px' }} />

        {/* Grade Progression Chart */}
        <GradeProgressionChart2 grades={assignments} />

        {/* Assignments List */}
        <ul
          style={{
            padding: 0,
            marginTop: '20px',
            listStyleType: 'none',
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
          }}
        >
          {assignments.map((assignment) => {
            const isCompleted = assignment.status === 'completed';
            const gradeColors = isCompleted
              ? getGradeColors(assignment.score)
              : { color: 'grey', background: '#f4f4f4' };

            return (
              <li
                key={assignment.id}
                style={{
                  backgroundColor:
                    hoveredAssignmentId === assignment.id ? '#FBFEFF' : 'white',
                  borderTop: '1px solid #ededed',
                  borderRadius: '10px',
                  marginBottom: '-10px',
                  padding: '15px 4%',
                  cursor: isCompleted ? 'pointer' : 'default',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  transition: 'background-color 0.3s',
                }}
                onMouseEnter={() => setHoveredAssignmentId(assignment.id)}
                onMouseLeave={() => setHoveredAssignmentId(null)}
                onClick={() => isCompleted && navigateToResults(assignment)}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    width: '50%',
                  }}
                >
                  {/* Assignment Name */}
                  <div
                    style={{
                      fontSize: '16px',
                      fontWeight: '500',
                      textAlign: 'left',
                      maxWidth: '400px',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      color: '#2c2c2c',
                    }}
                  >
                    {assignment.assignmentName}
                  </div>

                  {isCompleted ? (
                    <>
                      {/* Letter Grade */}
                      <div
                        style={{
                          fontSize: '16px',
                          fontWeight: '500',
                          borderRadius: '5px',
                          padding: '5px 10px',
                          marginLeft: 'auto',
                          marginRight: '20%',
                          textAlign: 'center',
                          width: '40px',
                        }}
                      >
                        {getLetterGrade(assignment.score)}
                      </div>

                      {/* Numeric Score */}
                      <div
                        style={{
                          fontSize: '14px',
                          fontWeight: '500',
                          color: gradeColors.color,
                          backgroundColor: gradeColors.background,
                          borderRadius: '5px',
                          padding: '5px 5px',
                          textAlign: 'center',
                          width: '40px',
                        }}
                      >
                        {assignment.score}%
                      </div>
                    </>
                  ) : (
                    <div
                      style={{
                        fontSize: '16px',
                        fontWeight: '500',
                        marginLeft: 'auto',
                        marginRight: '85px',
                        color:
                          assignment.status === 'paused'
                            ? '#FF4d00'
                            : assignment.status === 'in_progress'
                            ? '#FFA500'
                            : 'grey',
                        padding: '5px 10px',
                        textAlign: 'left',
                      }}
                    >
                      {assignment.status === 'paused'
                        ? 'Paused'
                        : assignment.status === 'in_progress'
                        ? 'In Progress'
                        : 'Not Started'}
                    </div>
                  )}
                </div>

                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '20px',
                    width: '40%',
                  }}
                >
                  {/* Assignment Date */}
                  <div
                    style={{
                      fontSize: '16px',
                      color: 'grey',
                      fontStyle: 'italic',
                      fontWeight: '500',
                      display: 'flex',
                      alignItems: 'center',
                      gap: '5px',
                    }}
                  >
                    {getStatusIcon(assignment.status)}
                    {isCompleted
                      ? assignment.submittedAt.toDate().toLocaleString(undefined, {
                          year: 'numeric',
                          month: 'short',
                          day: 'numeric',
                          hour: '2-digit',
                          minute: '2-digit',
                          hour12: true,
                        })
                      : assignment.dueDate
                      ? new Date(assignment.dueDate).toLocaleString(undefined, {
                          year: 'numeric',
                          month: 'short',
                          day: 'numeric',
                          hour: '2-digit',
                          minute: '2-digit',
                          hour12: true,
                        })
                      : 'No due date'}
                  </div>

                  {/* Assignment Type */}
                  <div
                    style={{
                      fontSize: '16px',
                      fontWeight: '600',
                      marginLeft: 'auto',
                      color:
                        assignment.type === 'AMCQ'
                          ? '#2BB514'
                          : assignment.type === 'OE'
                          ? '#020CFF'
                          : '#2BB514',
                      textAlign: 'right',
                      width: '60px',
                    }}
                  >
                    {assignment.type === 'AMCQ'
                      ? 'MC*'
                      : assignment.type === 'OE'
                      ? 'OE'
                      : 'MC'}
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
}

export default TeacherStudentGrades;
