import React from 'react';
import { Link } from 'react-router-dom';
import { db, auth } from '../../Universal/firebase';
import { collection, query, where, doc, getDoc, getDocs, updateDoc, arrayUnion, addDoc } from "firebase/firestore";
import { CalendarClock, SquareX, ChevronDown, ChevronUp , Shapes, CheckSquare, UserPlus} from 'lucide-react';

import { useState, useEffect } from 'react';
import {  useNavigate, useLocation } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useCollection } from 'react-firebase-hooks/firestore';
import Navbar from '../../Universal/Navbar';
import FooterAuth from '../../unAuthenticated/FooterAuth'; 
import CreateClassModal from './CreateClassModal';// Make sure this file exists in the same directory
import AnimationGreen from '../../Universal/AnimationGreen';
import AnimationAll from '../../Universal/AnimationAll';
import Loader from '../../Universal/Loader';
import { safeClassUpdate} from '../../teacherDataHelpers';
import { v4 as uuidv4 } from 'uuid';
import HomeNav from '../../Universal/HomeNavbar';
import { createClassWithGrouping,updateExistingClassGroups } from './FunctionsGC';
import Tutorials from './Tutorials';
const TeacherHome = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [user] = useAuthState(auth);
  const [teacherData, setTeacherData] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [schoolCode, setSchoolCode] = useState('');
 
  const [successMessage, setSuccessMessage] = useState('');
  const [newClassId, setNewClassId] = useState('');

  const [showCreateClassModal, setShowCreateClassModal] = useState(false); // Add this line
  useEffect(() => {
    const updateGroups = async () => {
      if (user && !localStorage.getItem('groupsUpdated')) {
        try {
          await updateExistingClassGroups(user.uid);
          localStorage.setItem('groupsUpdated', 'true');
        } catch (error) {
          console.error('Error updating class groups:', error);
        }
      }
    };
    updateGroups();
  }, [user]);

  // Modify your existing handleCreateClass function
  const handleCreateClass = async (e, period, classChoice) => {
    e.preventDefault();
    
    try {
      const className = `Period ${period}`;
      const classId = uuidv4();
      const classCode = Math.random().toString(36).substr(2, 6).toUpperCase();
      const teacherUID = auth.currentUser.uid;
      const periodStyle = periodStyles[period];
  
      const classData = {
        teacherUID: teacherUID,
        classId,
        classCode,
        className,
        classChoice,
        background: periodStyle.background,
        color: periodStyle.color,
        period
      };
  
      // Now using the renamed helper function
      const result = await createClassWithGrouping(classData, teacherUID);
      
      if (result.success) {
        setSuccessMessage(` ${className}, ${classChoice},  Created Successfully`);
        setNewClassId(classId);
        setShowCreateClassModal(false);
        return true;
      } else {
        throw new Error('Class creation failed');
      }
    } catch (err) {
      console.error('Error creating class:', err);
      alert(`Error creating class: ${err.message}. Please try again.`);
      throw err;
    }
  };
  useEffect(() => {
    const fetchTeacherData = async () => {
      if (!user) return;

      try {
        // First get the teacher document
        const teacherRef = doc(db, 'teachers', user.uid);
        const teacherSnap = await getDoc(teacherRef);
        
        if (teacherSnap.exists()) {
          const teacherData = teacherSnap.data();
          
          // Then fetch all classes where this teacher is the owner
          const classesQuery = query(
            collection(db, 'classes'),
            where('teacherUID', '==', user.uid)
          );
          
          const classesSnap = await getDocs(classesQuery);
          const classes = [];
          
          classesSnap.forEach((doc) => {
            classes.push({
              classId: doc.id,
              ...doc.data()
            });
          });
          
          setTeacherData({
            ...teacherData,
            classes: classes
          });
        }
      } catch (error) {
        console.error("Error fetching teacher data:", error);
      }
    };

    fetchTeacherData();
  }, [user]);

  const handleJoinOrganization = async () => {
    try {
      const schoolsQuery = query(collection(db, 'schools'), where('joinCode', '==', schoolCode));
      const schoolsSnap = await getDocs(schoolsQuery);

      if (!schoolsSnap.empty) {
        const schoolDoc = schoolsSnap.docs[0];
        const schoolData = schoolDoc.data();
        const schoolRef = doc(db, 'schools', schoolDoc.id);

        // Update teacher's document
        const teacherRef = doc(db, 'teachers', user.uid);
        await updateDoc(teacherRef, { 
          school: schoolData.schoolName, 
          schoolCode
        });

        // Add teacher UID to school's teachers array
        await updateDoc(schoolRef, { teachers: arrayUnion(user.uid) });

        // Update state
        setTeacherData(prevData => ({ 
          ...prevData, 
          school: schoolData.schoolName,
          schoolCode: schoolData.schoolCode
        }));
        setModalOpen(false);
        setSchoolCode('');
      } else {
        alert('Invalid school code.');
      }
    } catch (error) {
      console.error('Error joining organization:', error);
    }
  };
  useEffect(() => {
    if (location.state?.successMessage) {
      setSuccessMessage(location.state.successMessage);
      setNewClassId(location.state.classId);
      // Clear the message from location state
      navigate(location.pathname, { replace: true, state: {} });
    }
  }, [location, navigate]);

  const handleDismiss = () => {
    setSuccessMessage('');
    setNewClassId('');
  };
  
  const handleAddStudents = () => {
    setSuccessMessage('');
    setNewClassId('');
    navigate(`/class/${newClassId}/participants`);
  };
  const getRandomColor = () => {
    return hoverColors[Math.floor(Math.random() * hoverColors.length)];
  };
  const handleButtonHover = (e) => {
    if (e.currentTarget === e.target) {
      e.currentTarget.style.boxShadow = '0px 4px 4px 0px rgba(0, 0, 0, 0.25)';
    }
  };

  const handleButtonLeave = (e) => {
    e.currentTarget.style.boxShadow = 'none';
  };
  const hoverColors = [
    { background: '#F8CFFF', border: '#E01FFF' },
    { background: '#A3F2ED', border: '#4BD682' },
    { background: '#AEF2A3', border: '#006428' },
    { background: '#FFECA8', border: '#CE7C00' },
    { background: '#627BFF', border: '#020CFF' }
  ];
  
  const periodStyles = {
    1: { background: "#D4FFFD", color: "#1CC7BC", borderColor: "#1CC7BC" },
    2: { background: "#FCEDFF", color: "#E01FFF", borderColor: "#E01FFF" },
    3: { background: "#FFCEB2", color: "#FD772C", borderColor: "#FD772C" },
    4: { background: "#FFECA9", color: "#F0BC6E", borderColor: "#F0BC6E" },
    5: { background: "#DBFFD6", color: "#4BD682", borderColor: "#4BD682" },
    6: { background: "#F0EDFF", color: "#8364FF", borderColor: "#8364FF" },
    7: { background: "#8296FF", color: "#3D44EA", borderColor: "#3D44EA" },
    8: { background: "#FF8E8E", color: "#D23F3F", borderColor: "#D23F3F" },
  };


  const classesRef = collection(db, 'classes');
  const teacherUID = auth.currentUser.uid;
  const classQuery = query(classesRef, where('teacherUID', '==', teacherUID));
  const [querySnapshot, loading, error] = useCollection(classQuery);
  const [activeTab, setActiveTab] = useState('Classes'); // State for active tab

  const classes = querySnapshot?.docs.map(doc => ({
    id: doc.id,
    ...doc.data(),
  }));
 
  const getOrdinalSuffix = (num) => {
    const number = parseInt(num);
    if (number === 1) return "st";
    if (number === 2) return "nd";
    if (number === 3) return "rd";
    return "th";
  };
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

   return (
    <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: '#white', flexWrap: 'wrap' }}>
      <HomeNav userType="teacher" />
      <main style={{ flexGrow: 1, display: 'flex', flexDirection: 'column', alignItems: 'left', marginTop: '10px', backgroundColor: '#white', marginBottom: '230px' }}>
        
        {/* Success Message */}
        {successMessage && (
          <div style={{
            position: 'fixed',
            bottom: '20px',
            zIndex: '10000',
            right: '4%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}>
            <div style={{
              position: 'fixed',
              bottom: '16px',
              right: '16px',
              display: 'flex',
              alignItems: 'center',
              gap: '8px',
              backgroundColor: '#22C55E', // green-500
              color: 'white',
              padding: '0px 16px',
              borderRadius: '8px',
              boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
              animation: 'fadeIn 0.3s ease-in-out',
              zIndex: 50,
              fontFamily: "'Montserrat', sans-serif"
            }}>
              <CheckSquare style={{ width: '20px', height: '20px' }} />
              <p style={{ color: 'white', fontWeight: '500', marginRight: '20px' }}>{successMessage}</p>
              <button
                onClick={handleAddStudents}
                style={{
                  backgroundColor: 'white',
                  color: 'grey',
                  fontSize: '14px',
                  fontFamily: "'montserrat', sans-serif",
                  fontWeight: '500',
                  height: '30px',
                  border: '1px solid green',
                  opacity: '90%',
                  borderRadius: '5px',
                  marginRight: '10px',
                  cursor: 'pointer'
                }}
              >
                <UserPlus />
              </button>
              <button
                onClick={handleDismiss}
                style={{
                  backgroundColor: 'white',
                  opacity: '50%',
                  color: 'grey',
                  fontSize: '14px',
                  fontFamily: "'montserrat', sans-serif",
                  fontWeight: '500',
                  height: '30px',
                  border: '1px solid lightgrey',
                  borderRadius: '5px',
                  cursor: 'pointer'
                }}
              >
                Dismiss
              </button>
            </div>
          </div>
        )}

        {/* Loading and Error States */}
        {loading && <p>Loading...</p>}
        {error && <p>Error: {error.message}</p>}

        {/* Tab Navigation */}
        <div style={{ width: '100%', borderBottom: '1px solid lightgrey', marginBottom: '-40px', alignItems: 'center' }}>
          <h1 style={{
            fontSize: '30px',
            marginLeft: 'calc(4% + 200px)',
            fontWeight: '500'
          }}>
            Home
          </h1>

          <button
            onClick={() => handleTabClick('Classes')}
            style={{
              background: 'none',
              border: 'none',
              fontSize: '14px',
              cursor: 'pointer',
              marginLeft: 'calc(4% + 188px)',
              fontWeight: "600",
              padding: '12px 10px',
              fontFamily: "'Montserrat', sans-serif",
              borderBottom: activeTab === 'Classes' ? '2px solid #00BBFF' : '2px solid transparent',
              color: activeTab === 'Classes' ? '#00BBFF' : 'grey',
            }}
          >
            Classes
          </button>

          <button
            onClick={() => handleTabClick('Tutorials')}
            style={{
              background: 'none',
              border: 'none',
              fontSize: '14px',
              cursor: 'pointer',
              marginLeft: '50px',
              fontWeight: "600",
              padding: '12px 10px',
              fontFamily: "'Montserrat', sans-serif",
              borderBottom: activeTab === 'Tutorials' ? '2px solid #00BBFF' : '2px solid transparent',
              color: activeTab === 'Tutorials' ? '#00BBFF' : 'grey',
            }}
          >
            Tutorials
          </button>
        </div>

        {/* Content Area */}
        <div style={{
          marginTop: '40px',
          display: 'flex',
          flexWrap: 'wrap',
          width: 'calc(92% - 200px)',
          marginLeft: 'calc(200px + 4%)',
          fontFamily: "'montserrat', sans-serif",
          backgroundColor: 'white',
        }}>
          {activeTab === 'Classes' ? (
            <>
              {/* Join School Modal */}
              {modalOpen && (
                <div style={{
                  position: 'fixed',
                  top: '0',
                  left: '0',
                  right: '0',
                  bottom: '0',
                  backdropFilter: 'blur(15px)',
                  backgroundColor: 'rgba(255, 255, 255, 0.8)',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  zIndex: '100'
                }}>
                  <div style={{
                    backgroundColor: 'transparent',
                    padding: '20px',
                    borderRadius: '10px',
                    textAlign: 'center',
                  }}>
                    <h3
                      style={{ fontSize: '80px', fontFamily: '"montserrat", sans-serif', marginTop: '-50px', marginBottom: '0px' }}
                    >School Code</h3>
                    <input
                      type="text"
                      value={schoolCode}
                      onChange={(e) => setSchoolCode(e.target.value)}
                      style={{
                        fontFamily: "'montserrat', sans-serif",
                        fontSize: '20px',
                        background: "white",
                        borderRadius: '20px',
                        border: '4px solid lightgrey',
                        width: '440px',
                        paddingLeft: '20px',
                        paddingRight: '20px',
                        paddingTop: '10px',
                        paddingBottom: '10px',
                        fontWeight: 'bold',
                        textAlign: 'Left',
                        outline: 'none',
                      }}
                    />
                    <br />
                    <button
                      onClick={handleJoinOrganization}
                      style={{
                        padding: '10px 20px',
                        backgroundColor: '#AEF2A3',
                        border: '5px solid #45B434',
                        color: '#45B434',
                        borderRadius: '15px',
                        cursor: 'pointer',
                        marginTop: '30px',
                        fontFamily: "'montserrat', sans-serif",
                        fontWeight: 'bold',
                        fontSize: '30px',
                        width: '45%',
                        marginRight: '10px'
                      }}
                    >
                      Join
                    </button>
                    <button
                      onClick={() => setModalOpen(false)}
                      style={{
                        padding: '10px 20px',
                        backgroundColor: '#f4f4f4',
                        border: '5px solid lightgrey',
                        color: 'grey',
                        borderRadius: '15px',
                        cursor: 'pointer',
                        marginTop: '30px',
                        fontFamily: "'montserrat', sans-serif",
                        fontWeight: 'bold',
                        fontSize: '30px',
                        width: '45%',
                        marginRight: '10px'
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              )}

              {/* Classes List */}
              {classes && classes
                .sort((a, b) => a.className.localeCompare(b.className))
                .map((classItem, index) => {
                  const periodNumber = parseInt(classItem.className.split(' ')[1]);
                  const periodStyle = periodStyles[periodNumber] || { background: '#F4F4F4', color: 'grey' };
                  const suffix = getOrdinalSuffix(periodNumber);
                  return (
                    <button 
                      key={classItem.id}
                      onClick={() => navigate(`/class/${classItem.id}/`)} 
                      style={{ 
                        marginRight: '3%',
                        width: "300px",
                        marginTop: '20px', 
                        height: '150px',
                        display: 'flex',
                        backgroundColor: 'transparent',  
                        color: 'grey', 
                        cursor: 'pointer',
                        border: '1px solid #ededed',
                        boxShadow: 'rgba(50, 50, 205, 0.01) 0px 2px 5px 0px, rgba(0, 0, 0, 0.01) 0px 1px 1px 0px',
                        borderRadius: '15px', 
                        textAlign: 'left',
                        flexDirection: 'column',
                        alignItems: 'left',
                        transition: '.2s', 
                        position: 'relative',
                        zIndex: '1',
                        fontFamily: "'montserrat', sans-serif",
                        transform: 'scale(1)',
                      }}
                      onMouseEnter={(e) => {
                        e.target.style.borderColor = '#dddddd';
                      }}
                      onMouseLeave={(e) => {
                        e.target.style.borderColor = '#ededed';
                      }}
                      className="hoverableButton"
                    >
                      <div style={{ display: 'flex', padding: '20px' }}>
                        <h1 style={{
                          fontSize: '30px',
                          borderRadius: '5px',
                          backgroundColor: periodStyle.background,
                          color: periodStyle.color,
                          height: '40px',
                          marginTop: '6px',
                          lineHeight: '40px',
                          fontWeight: '500',
                          padding: '0px 10px',
                        }}>
                          {periodNumber}
                          <span style={{
                            fontSize: '30px',
                            fontWeight: '500',
                            marginLeft: '2px',
                          }}>
                            {suffix}
                          </span>
                        </h1>
                        <h1 style={{
                          fontSize: '40px',
                          color: periodStyle.color,
                          margin: 0,
                          fontWeight: '500',
                          marginLeft: '10px',
                        }}>
                          Period
                        </h1>
                      </div>

                      <p style={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        marginLeft: '20px',
                        textAlign: 'left',
                        color: 'lightgrey',
                        fontWeight: '500', 
                        marginTop: '0px',
                        fontSize: '16px',
                        whiteSpace: 'nowrap',
                        width: '268px',
                        background: 'transparent',
                      }}>
                        {classItem.classChoice}
                      </p>
                    </button>
                  );
                })
              }
            </>
          ) : (
            // Render Tutorials Component
            <Tutorials />
          )}
        </div>

        {/* Create Class Button */}
        {activeTab === 'Classes' && (
          <div style={{ fontFamily: "'montserrat', sans-serif", width: '100%', display: 'flex', height: '30px', marginBottom: '10px', marginTop: '30px' }}>
            <button
              onClick={() => setShowCreateClassModal(true)}
              style={{
                marginRight: '4%', 
                backgroundColor: 'white',
                border: '1px solid lightgrey',
                marginLeft: 'calc(200px + 4%)',
                fontSize: '16px', 
                width: '140px',
                marginTop: '0px',
                transition: '.3s', 
                color: 'grey',
                borderRadius: '5px',
                padding: '10px 5px', 
                fontWeight: '500',
                fontFamily: "'montserrat', sans-serif",
                textAlign: 'center', 
                lineHeight: '20px',
                cursor: 'pointer',
                height: '40px',
              }}
              onMouseEnter={(e) => {
                e.target.style.color = '#45B434';
              }}
              onMouseLeave={(e) => {
                e.target.style.color = 'grey';
              }}
            >
              Create Class +
            </button>
          </div>
        )}

        {/* Centered School Info or Join Button */}
        <div style={{ width: '1000px', marginRight: 'auto', marginLeft: 'auto', marginTop: '30px', display: 'flex' }}>
          {teacherData && teacherData.school ? (
            <div style={{ marginTop: '10px', marginLeft: 'auto', marginRight: 'auto', fontSize: '30px', color: 'lightgrey' }}>
              <h4 style={{ marginLeft: '-400px', fontFamily: "'montserrat', sans-serif", marginTop: '0px', fontSize: '30px', color: 'lightgrey' }}>
                {teacherData.school}
              </h4>
            </div>
          ) : (
            <button
              onClick={() => setModalOpen(true)}
              style={{
                marginRight: 'auto',
                backgroundColor: 'white',
                border: '4px solid white',
                fontWeight: '500',
                color: 'grey',
                borderRadius: '10px',
                padding: '20px 20px',
                fontFamily: "'montserrat', sans-serif",
                height: '45px',
                cursor: 'pointer',
                marginTop: '0px',
                lineHeight: '0px',
                boxShadow: '1px 1px 5px 1px rgb(0,0,155,.1)',
                marginLeft: '-570px',
                fontSize: '20px',
                transition: '.3s',
              }}
              onMouseEnter={(e) => {
                e.target.style.color = '#020CFF';
              }}
              onMouseLeave={(e) => {
                e.target.style.color = 'grey';
              }}
            >
              Join School
            </button>
          )}
        </div>

        {/* Create Class Modal */}
        {showCreateClassModal && (
          <CreateClassModal 
            handleCreateClass={handleCreateClass}
            setShowCreateClassModal={setShowCreateClassModal}
          />
        )}

      </main>
      <FooterAuth style={{ marginTop: '100px' }}/>
    </div>
  );
};

export default TeacherHome;